import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";
import Menu from './components/organisms/menu';
import Dish from './components/organisms/dish';

function App() {
	return (
		<Router>
			<Switch>
				<Route exact path="/"><Redirect to="/weeklymenu/current" /></Route>

				<Route exact path="/weeklymenu"><Redirect to="/weeklymenu/current" /></Route>

				<Route path="/weeklymenu/:calweek" component={Menu} />

				<Route path="/dish" component={Dish}/>
			</Switch>
		</Router>
	);
}

export default App;
