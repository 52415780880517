import React, {useEffect, useState} from "react";
import {
	useParams
} from "react-router-dom";
import Weekday from './../molecules/weekday';
import MenuPagination from './../molecules/menupagination';

function Menu() {
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [weeklymenu, setWeeklymenu] = useState([]);
	//const [calweek, setCalweek] = useState([]);
	let {calweek} = useParams();

	useEffect( ()=> {
		setIsLoaded(false);
		fetch('https://pwa-backend.ifabrik-digital.de/speiseplan/patienten/weeklymenu/'+calweek, {headers: {'Accept': 'application/json' }})
			.then(string => string.json())
			.then(
				(result) => {
					console.log(result);
					setWeeklymenu(result);
					setError(null);
					setIsLoaded(true);
				},
				(error) => {
					setError(error);
					setIsLoaded(true);
				});
	},[calweek]);


	const shareWeek = () => {
		if (navigator.share) {
			const url = window.location.href;
			const shareUrl = url.replace(window.location.pathname, '') + weeklymenu.links.self;
			navigator.share({
				title: 'weekly menu of week ' + weeklymenu.data.attributes.week,
				text: 'provided by ifab daily menu',
				url: shareUrl
			})
			.then(()=> console.log('shared data succesfully'))
			.catch((error)=> console.log('error whil shareing: ', error));
		} else {
			alert('Funtion not supported in this browser!')
		}
	};

	if (error) {
		return (
			<div className={"dailymenu-list"}>
				<MenuPagination weeklymenu={weeklymenu}/>
				<div>Error: {error.message}</div>
			</div>

		);
	} else if (!isLoaded) {
		const dummyDays = [1, 2, 3, 4, 5, 6, 7];
		return (
			<div className={"dailymenu-list"}>

				<MenuPagination weeklymenu={weeklymenu}/>

				<button className="btn btn-primary btn-sm">share</button>

				{dummyDays.map((day) =>
					<Weekday key={day.toString()} isLoaded={isLoaded}/>
				)}

			</div>
		);
	} else {
		return (
			<>
				<div className={"dailymenu-list"}>

					<MenuPagination weeklymenu={weeklymenu} isLoaded={isLoaded}/>

					<button onClick={shareWeek} className="btn btn-primary btn-sm">share</button>

					{weeklymenu.data.attributes.menu.map((weekday) =>
						<Weekday key={weekday.day.timestamp} weekday={weekday} isLoaded={isLoaded}/>
					)}


				</div>
			</>
		);
	}
}


export default Menu;
