import React from "react";
import {
	Link,
	useParams
} from "react-router-dom";
function MenuPagination(props) {
  let {calweek} = useParams();

	if (!props.isLoaded) {
		return (
      <div className="dailymenu-pagination">
        <div className="btn btn-secondary btn-sm -circle ">
          <i className="fas fa-chevron-left" aria-hidden="true"></i>
          <span className="btn__text">Prev</span>
        </div>
        <h2 className="dailymenu-pagination__current">{calweek}</h2>
        <div className="btn btn-secondary btn-sm -circle ">
          <i className="fas fa-chevron-right" aria-hidden="true"></i>
          <span className="btn__text">Next</span>
        </div>
      </div>
		);
	} else {
		return (
      <div className="dailymenu-pagination">
        <Link to={props.weeklymenu.links.previous}>
          <div className="btn btn-secondary btn-sm -circle ">
            <i className="fas fa-chevron-left" aria-hidden="true"></i>
            <span className="btn__text">Prev</span>
          </div>
        </Link>
        <h2 className="dailymenu-pagination__current">{calweek}</h2>
        <Link to={props.weeklymenu.links.next}>
          <div className="btn btn-secondary btn-sm -circle ">
            <i className="fas fa-chevron-right" aria-hidden="true"></i>
            <span className="btn__text">Next</span>
          </div>
        </Link>
      </div>
		);
	}

}

export default MenuPagination;
