import React from "react";

function Course(props) {
	if (!props.isLoaded) {
		return (
			<span className="badge badge-primary">
				_Course
			</span>
		);
	} else {
		return (
			<span className="badge badge-primary">
				{props.course}
			</span>
		);
	}

}

export default Course;