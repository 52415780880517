import React from "react";
import Modals from './../molecules/modals';
import Property from './../atoms/property';
import Course from './../atoms/course';

function Dish(props) {
	if (!props.isLoaded) {
		return (
			<div className="card">
				<div className="card__body">
					<div className="badges">
						<Course isLoaded={props.isLoaded} />
						<Property isLoaded={props.isLoaded} />
					</div>

					<div className="h3 title" role="heading" aria-level="3">
						<span>Titel</span>
					</div>

					<p>infotext</p>

					<Modals isLoaded={props.isLoaded} />
				</div>

				<div className="card__footer">
					<div className="left">
						<a data-toggle="modal" href="#dailymenu-modal-{dayItem.uid}" aria-controls="dailymenu-modal-{dayItem.uid}">
							<i aria-hidden="true" className="far fa-flask"></i>
							additives / allergenics
						</a>
					</div>
				</div>
			</div>
		);
	} else {
		if (props.dish === false) {
			return (
				false
			);
		}
		else {
			let badges = (
				<div className="badges">
					<Course course={props.course} isLoaded={props.isLoaded} />
					{props.dish.properties.map((property) =>
						<Property property={property} isLoaded={props.isLoaded} />
					)}
				</div>
			);
			return (
				<div className="card">
					<div className="card__body">

						{badges}

						<div className="h3 title" role="heading" aria-level="3">
							<span>{props.dish.dish}</span>
						</div>

						{props.dish.infotext &&
						<p>{props.dish.infotext}</p>}

						<Modals isLoaded={props.isLoaded} />
					</div>

					<div className="card__footer">
						<div className="left">
							<a data-toggle="modal" href="#dailymenu-modal-{dayItem.uid}" aria-controls="dailymenu-modal-{dayItem.uid}">
								<i aria-hidden="true" className="far fa-flask"></i>
								additives / allergenics
							</a>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default Dish;
