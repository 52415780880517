import Date from './../atoms/date';
import Dish from './../organisms/dish';

function Weekday(props) {
	if (!props.isLoaded) {
		const courses = [1, 2, 3, 4, 5, 6];
		return (
			<div className={"dailymenu-day"} data-dailymenu-day={props.day}>
				<h3 className={"dailymenu-day__title"}>
					<Date isLoaded={props.isLoaded}/>
				</h3>
				<div className="dailymenu-day__content">
					<div className="deck" data-column="4">
						<div className="deck__container">
							{courses.map((number) =>
								<Dish key={number.toString()} isLoaded={props.isLoaded}/>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className={"dailymenu-day"} data-dailymenu-day={props.weekday.day.date}>
				<h3 className={"dailymenu-day__title"}>
					<Date day={props.weekday.day} isLoaded={props.isLoaded}/>
				</h3>
				<div className="dailymenu-day__content">
					<div className="deck" data-column="4">
						<div className="deck__container">
							{props.weekday.dishes &&
								Object.keys(props.weekday.dishes).map( (key, i) =>
								<Dish key={key} course={key} dish={props.weekday.dishes[key]} isLoaded={props.isLoaded}/>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Weekday;
