import React from "react";

function Modals(props) {
	if (!props.isLoaded) {
		return (
			<div className="modal fade dailymenu-modal-additive" id="dailymenu-modal-{dayItem.uid}" tabIndex="-1" role="dialog" aria-labelledby="dailymenu-modal-title-{dayItem.uid}" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-m" role="document">
					<div className="modal-content">
						<div className="modal-header content-box__header primary-dark">
							<h4 className="modal-title" id="dailymenu-modal-title-{dayItem.uid}">
								<span>additives/allergenics</span>
							</h4>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							allergenics
							additives
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-default btn-primary" data-dismiss="modal">
								close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="modal fade dailymenu-modal-additive" id="dailymenu-modal-{dayItem.uid}" tabIndex="-1" role="dialog" aria-labelledby="dailymenu-modal-title-{dayItem.uid}" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-m" role="document">
					<div className="modal-content">
						<div className="modal-header content-box__header primary-dark">
							<h4 className="modal-title" id="dailymenu-modal-title-{dayItem.uid}">
								<span>additives/allergenics</span>
							</h4>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							allergenics
							additives
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-default btn-primary" data-dismiss="modal">
								close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

}

export default Modals;