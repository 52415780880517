import React from "react";

function Date(props) {
	if (props.error) {
		return <div>Error: {props.error.message}</div>;
	} else if (!props.isLoaded) {
		return (
			<>
                <i aria-hidden={"true"} className={"far fa-calendar"}> < /i>
				___
	        </>
	    );
	} else {
		return (
			<>
	            <i aria-hidden={"true"} className={"far fa-calendar"}> </i>
				 {props.day.name}
	        </>
	    );
	}
}


export default Date;
