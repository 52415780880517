import React from "react";

function Property(props) {
	if (!props.isLoaded) {
		return (
			<span className="badge badge-primary-light">
				_property
			</span>
		);
	} else {
		return (
			<span className="badge badge-primary-light">
				{props.property.property}
			</span>
		);
	}

}

export default Property;